import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { BrowserOptions } from '@sentry/angular-ivy';

if (environment.production) {
  enableProdMode();
}

const browserOptions: BrowserOptions = {
  dsn: "https://dc7c2e4aba4749855e7a0cd26fe0b920@sentry.baytechconsulting.com/3",
  integrations: [],
  environment: `SPA-${environment.sentryEnvName}`,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  sampleRate:1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: []
};

if (environment.production)
{
  browserOptions.integrations = [Sentry.replayIntegration({
    maskAllText: false,
    maskAllInputs: false,
    blockAllMedia: false,
    maxReplayDuration: 120000,
    networkDetailAllowUrls: ["americanalliedhealth"]
  })];
  browserOptions.replaysOnErrorSampleRate = 1.0;
  browserOptions.replaysSessionSampleRate = 0.01;
}

Sentry.init(browserOptions);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
